import React from "react";
import CommonBanner from "../components/Common/Banner";
import ServiciosIndex from "../components/Servicios/Index";
import HomeTwoAbout from "../components/Home/About";
import Footer from "../components/Common/Footer";
const Servicios = () => {
  return (
    <>
      <CommonBanner heading="Servicios" page="Servicios" />
      <ServiciosIndex />
      <HomeTwoAbout/>
    
    </>
  );
};

export default Servicios;
