import img1 from '../../../assets/img/service/01.png'
import img2 from '../../../assets/img/service/02.png'
import img3 from '../../../assets/img/service/03.png'
import img4 from '../../../assets/img/service/04.png'
import img5 from '../../../assets/img/service/05.png'
import img6 from '../../../assets/img/service/06.png'

export const ServiceData = [
    {
        img: img1,
        heading: "Tralado de Vehículos",
        para: "Trasladamos vehículos nuevos del interior del puerto a los patios externos.\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n",
        button: "Read More"
    },
    {
        img: img2,
        heading: "Gestión de Logística",
        para: "Gestionamos la logística  entre patios y agencias aduanales para asegurar el plan de carga programado y correcta operación de manera integral.",
        button: "Read More"
    },
    {
        img: img3,
        heading: "Traslado de vehículos a nivel nacional",
        para: "Traladamos vehículos a nivel nacional con unidades equipadas y personal capacitado y con experiencia, equipos asegurados con cobertura completa de la carga.",
        button: "Read More"
    },
    {
        img: img4,
        heading: "Patio Logístico",
        para: "Patio logístico para pensión y resguardo de unidades con capacidad de almacenamiento ",
        button: "Read More"
    },
    {
        img: img5,
        heading: "Servicio de Gestión",
        para: "Servicio de gestion de los vehículos para armado y deslinde de viajes con la diversas transportistas.",
        button: "Read More"
    },
    {
        img: img6,
        heading: "Taller Especializado",
        para: "Taller especializado para la reparación, detallado, lavado, desinfección y accesorización de los vehículos.",
        button: "Read More"
    },
   
]