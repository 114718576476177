export const MenuData = [
    {
        name: "Inicio",
        href: "/", 
        has_children: false,
       
    },
    {
        name: "Nosotros",
        href: "/about",
        has_children: false,
    },
    {
        name: "Servicios",
        href: "/servicios",
        has_children: false,
       
    },
    {
        name: "Contacto",
        href: "/contact",
        has_children: false,
    },
]