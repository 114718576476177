import React from "react";
const ContactInfo = () => {
  return (
    <div className="contact_info_wrapper">
      <div className="contact_item">
        <h5>Dirección</h5>
        <p>Río Concepción No. 346 - 2 Esq. Río Mezquital Lomas de Bellavista, Ciudad Lázaro Cárdenas, Michoacán.</p>
      </div>
      <div className="contact_item">
        <h5>Teléfono</h5>
        <p>753 112 86 41</p>
        
      </div>
      <div className="contact_item">
        <h5>Cotacto por correo</h5>
        <p>direccion@mvautotraslados.com</p>
        
      </div>
    </div>
  );
};

export default ContactInfo;
