import React from 'react'

const Mision = () => {
    return (
        <>
            <section id="home_two_service">
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <div className="about_service_text">
                                <div className="heading-left-border">
                                    <h2>MISIÓN</h2>
                                </div>
                                <p>Proporcionar un servicio eficaz y de alta calidad, fruto de la experiencia y dedicación, con el objetivo de posicionarnos como su aliado preferente en la gestión logística, adaptándonos de manera completa y personalizada a sus requerimientos.</p>


                            </div>
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <div className="about_service_text">
                                <div className="heading-left-border">
                                    <h2>VISIÓN</h2>
                                </div>
                                <p>
                                    Aspiramos a ser una empresa destacada y renombrada por la excelencia de nuestro servicio y la calidad en el traslado de vehículos a lo largo del territorio nacional.
                                    Nuestro compromiso radica en la búsqueda constante de la excelencia y el crecimiento continuo para asegurar la aprobación y satisfacción de nuestros clientes.
                                </p>
                            </div>
                        </div>
                    </div>
                   
                    
                    
                </div>
            </section>
        </>
    )
}

export default Mision;