import React from "react";
import HomeBanner from "../components/Home/Banner";
import HomeTwoAbout from "../components/Home/About";
import OurAdvantage from "../components/Home/Our_Advantages";
const Index = () => {
  return (
    <>
      <HomeBanner />
      <HomeTwoAbout/>
      <OurAdvantage/>
    </>
  );
};

export default Index;
