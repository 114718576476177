import React from 'react'
import CommonBanner from '../components/Common/Banner';
import Mision from '../components/About/Mision';
import HomeTwoAbout from '../components/Home/About';
import Valores from '../components/About/Valores';
import Porque from '../components/About/Porque';
import Footer from '../components/Common/Footer';

const Nosotros = ()=>{
    return(
        <>
         <CommonBanner heading="Nosotros" page="Nosotros"/>
         <HomeTwoAbout/>
         <Mision/>
         <Valores/>
         <Porque/>
        
        </>
    )
}

export default Nosotros;