import React from "react";
import SectionHeading from "../Common/SectionHeading";
import { ServiceData } from "../Common/Servicio/ServiceData";
import ServiceCard from "../Common/Servicio/ServiceCard";
const ServiciosIndex = () => {
  return (
    <>
      <section id="services_page">
        <div className="container">
          <SectionHeading
            heading="Somos tu mejor aliado"
            para="En la operación logística de transporte de vehículos nuevos a nivel local."
          />
          <div className="service_wrapper_top">
            <div className="row">
              {ServiceData.map((data, index) => (
                <div className="col-lg-4" key={index}>
                  <ServiceCard
                    img={data.img}
                    heading={data.heading}
                    para={data.para}
                    // button={data.button}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiciosIndex;
