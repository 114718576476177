import React from 'react'
import CommonBanner from '../components/Common/Banner';
import ContactoInner from '../components/Contacto';

const Contacto = ()=>{
    return(
        <>
        <CommonBanner heading="Contacto" page="Contacto"/>
        <ContactoInner/>
        </>
    )
}

export default Contacto;