import React from 'react'
import img1 from '../../assets/img/roar/Flat-Racks.jpg'
const Porque = () => {
    return (
        <>
            <section id="home_about_area">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_content">
                                <div className="heading-left-border">
                                    <h2>¿POR QUÉ MV AUTOTRASLADOS ES TU MEJOR OPCIÓN?</h2>
                                </div>
                                <p>
                                    <ul className="list-items">
                                        <li> <i class="fa fa-check"></i> Experiencia y habilidades para cubrir la operación logística de movimiento de carga, resguardo y almacenamiento de la misma.</li>
                                        <li><i class="fa fa-check"></i> Somos fabricantes de nuestras plataformas para garantizar siempre el equipo.</li>
                                        <li><i class="fa fa-check"></i> Excelente relación con agencias aduanales y personal de los patios operativos.</li>
                                        <li><i class="fa fa-check"></i> Plan de negocios variable dependiendo las necesidades de nuestros clientes.</li>
                                        <li><i class="fa fa-check"></i> Personal capacitado y con experiencia.</li>
                                        <li><i class="fa fa-check"></i> Empresa reglamentada y con todos los permisos y licencias para trabajar al igual que nuestros equipos.</li>
                                        <li><i class="fa fa-check"></i> Contamos con equipos nuevos para el movimiento de tus viajes locales y foraneos.</li>
                                        <li><i class="fa fa-check"></i> Sin intermediarios en los servicios, somos una empresa establecida con todos los permisos para trabajar.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_img">
                                <img src={img1} alt="About_Img" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
export default Porque;