import React from 'react'
import { Link } from 'react-router-dom';
// Import Logo Import
import logo from '../../assets/img/logo.jpg'

const FooterItems = [
    {
        title: "",
        links: [
            { linkTitle: "Inicio", link: "/" },
            { linkTitle: "Nosotros", link: "/about" },
            { linkTitle: "Servicios", link: "/servicios" },
            { linkTitle: "Contacto", link: "/contact" }
        ],
    }
]

const Footer = () => {
    return (
        <>
            <footer id="footer_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="footer_wedget">
                                {/* <img src={logo} alt="logo_img"/> */}
                                <p></p>
                                <div className="footer_social_icon">
                                    <a href="#!"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#!"><i className="fab fa-twitter"></i></a>
                                    <a href="#!"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#!"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="footer_wedget">
                                {FooterItems.slice(0, 1).map((data, index) => (
                                    <FooterWidget data={data} key={index} />
                                ))}

                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                            <div className="footer_wedget">
                                <h4>Contacto</h4>
                                <div className="contact-info-footer">
                                    <ul>
                                        <li><i className="fas fa-map-marker-alt"></i>INFONAVIT, 60950 Cdad. Lázaro Cárdenas, Mich.</li>
                                        <li><i className="far fa-envelope"></i> <a
                                            href="mailto:hello@demo.com"> direccion@mvautotraslados.com</a></li>
                                        {/* <li><i className="far fa-envelope"></i> <a
                                            href="mailto:hello@demo.com">contact@floza.com</a></li> */}
                                        <li><i className="fas fa-phone-volume"></i> <a href="tel:7531128641"> 753 112 86 41</a></li>
                                        <li><i className="far fa-clock"></i>Lunes - Domingo 08:00 - 19:00</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer

export const FooterWidget = ({ data }) => (
    <>
        <h4>{data.title}</h4>
        <ul>
            {data.links.map((datas, index) => (
                <li key={index}><Link to={datas.link}>{datas.linkTitle}</Link></li>
            ))}
        </ul>
    </>
)