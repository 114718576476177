import ContactInfo from "./Contacto/ContactInfo";
import Mapa from "./Contacto/Mapa";

const ContactoInner = () => {
  return (
    <>
      <section id="contact_area_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_heading_center">
                <h2>Información de Contacto</h2>
              </div>
            </div>
          </div>
          <div className="map_area">
            <Mapa />
          </div>
          <div className="contact_form_info_area">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  {/* <ContactForm/> */}
                  <ContactInfo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p></p>
    </>
  );
};

export default ContactoInner;
