import React from "react";
import { Link } from "react-router-dom";

// TopHeader Area
const TopHeader = () => {
  return (
    <>
      <div className="top-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <ul className="left-info">
                <li>
                  <a href="mailto:hola@roar-logistic.com">
                    <i className="far fa-envelope"></i>
                    direccion@mvautotraslados.com
                  </a>
                </li>
                <li>
                  <a href="tel:+527531128641">
                    <i className="fas fa-phone-volume"></i>
                    +52 753 112 86 41
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <ul className="right-info"></ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
