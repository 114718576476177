import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
// Import Page Layout
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
import CopyRight from "./components/Common/CopyRight";
// Import All Components
import Index from "./page";
// Import ScrollToTop Components
import ScrollToTop from "./components/ScrollToTop";
import Servicios from "./page/servicios";
import Nosotros from "./page/nosotros";
import Contacto from "./page/contacto";
function App() {
  return (
    <>
      <BrowserRouter>
        <Router>
          <ScrollToTop>
            <Navbar />
            <Switch>
              <Route path="/" exact component={Index} />
              <Route path="/servicios" exact component={Servicios} />
              <Route path="/about" exact component={Nosotros} />
              <Route path="/contact" exact component={Contacto} />
            </Switch>
            <Footer/>
            <CopyRight />
          </ScrollToTop>
        </Router>
      </BrowserRouter>
    </>
  );
}

export default App;
