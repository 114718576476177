import React from 'react'

const Valores = ()=>{
    return(
        <section id="team_area">
        <div className="container">
        <div className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="about_service_text">
                                <div className="heading-left-border">
                                    <h2>NUESTRA FILOSOFÍA Y VALOR EMPRESARIAL</h2>
                                </div>
                                <p>
                                    En MV AUTOTRASLADOS, reconocemos que para alcanzar la satisfacción de nuestros clientes y cumplir con los objetivos de la empresa, es fundamental añadir
                                    un valor adicional a la experiencia del personal y a la eficiencia de nuestros procesos. Por ello, nuestra excelencia se fundamenta en la dedicación que aplicamos
                                    en cada tarea para garantizar el éxito deseado; la dedicación constituye nuestro valor primordial.
                                </p>
                            </div>
                        </div>
                    </div>
            
        </div>
    </section>
    )
}

export default Valores;