import img1 from '../../../assets/img/svg/house.svg'
import img2 from '../../../assets/img/svg/crm.svg'
import img3 from '../../../assets/img/svg/clock.svg'

export const OurAdventagesData = [
    [
        {
            img: img3,
            para:"Experiencia y habilidades para cubrir la operación logística de movimiento de carga"
        },
        {
            img: img3,
            para:"Fabricantes de nuestras plataformas para garantizar siempre el equipo"
        },
        {
            img: img3,
            para:"Excelente relación con agencias aduanales y personal de los patios operativos"
        }
    ],
    [
        {
            img: img1,
            para: "Plan de negocios variable dependiendo las necesidades de nuestros clientes"
        },
        {
            img: img2,
            para: "Empresa reglamentada y con todos los permisos y licencias para trabajar al igual que nuestros equipos"
        },
        {
            img: img3,
            para: "Personal capacitado y con experiencia "
        }
    ],
    [
        {
            img: img1,
            heading: "Warehouse Facility",
            para: "Contamos con equipos nuevos para el movimiento de tus viajes locales y foraneos"
        },
        {
            img: img2,
            heading: "Customer Satisfaction",
            para: "Sin intermediarios en los servicios, somos una empresa establecida con todos los permisos para trabajar"
        }
    ]
]